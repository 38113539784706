body {
  margin: 0;
  font-family: -apple-system, "Lato", BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  min-width: 0 !important;
}

#zmmtg-root {
  display: none;
}

#start-my-meeting-swz {
  visibility: hidden;
}

.meeting-container-personalized {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
  flex-direction: column;
}